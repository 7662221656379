export function getLocalUserInfo() {
  const localString = window.sessionStorage.getItem('user_info');
  let localUserInfo = null;
  try {
    localUserInfo = JSON.parse(localString);
  } catch (e) {
    localUserInfo = {};
  }
  return localUserInfo;
}

export function setLocalUserInfo(info = {}) {
  try {
    window.sessionStorage.setItem('user_info', JSON.stringify(info));
  } catch (e) {}
}
/**
 * 获取url参数
 * @param arg
 * @returns {[]}
 */
export const getUrlParams1 = (arg) => {
    try {
        let url = arg || window.location.href;
        let urlParams = '';
        let paramPos = url.indexOf("=");
		if(paramPos==-1){
			return '/'
		}else{
			urlParams=url.slice(paramPos+1)
			return urlParams;
		}
    }
    catch (e)
    {
        console.log(e);
        return {};
    }
}

/**
 * 获取url参数
 * @param arg
 * @returns {[]}
 */
export const getUrlParams2 = (arg) => {
    try {
        let url = arg || window.location.toString();
        let urlParams = [];
        let paramPos = url.lastIndexOf("?");
        if (paramPos != -1) {
            let anchorPos = url.lastIndexOf("#")
            //let paramAry = (url.substr(url.indexOf("?") + 1)).split('&')
            let paramAry = ((paramPos < anchorPos) ? url.substring(paramPos + 1, anchorPos) : url.substring(paramPos + 1)).split("&")
            for (let i = 0; i < paramAry.length; i++) {
                urlParams[paramAry[i].split("=")[0]] = unescape(paramAry[i].split("=")[1])
            }
        }
        console.log(urlParams)
        return urlParams;
    }
    catch (e)
    {
        console.log(e);
        return {};
    }
}


export function formatPhone(phone) {
  return phone.replace(phone.substr(3,4), '*'.repeat(4));
}

export function formatName(name) {
	var len=name.length
  return name.replace(name.substr(1), '*'.repeat(len-1));
}

export function clearLocalUserInfo() {
  try {
    window.sessionStorage.removeItem('user_info');
  } catch (e) {}
}

export function getDesignUserRole() {
  const localString = window.sessionStorage.getItem('design_user_role');
  let userRole = 0;
  try {
    userRole = JSON.parse(localString);
  } catch (e) {
    userRole = 0;
  }
  return userRole;
}

export function setDesignUserRole(userRole = 0) {
  try {
    window.sessionStorage.setItem('design_user_role', JSON.stringify(userRole));
  } catch (e) {}
}

export function getDesignLocalUserInfo() {
  const localString = window.sessionStorage.getItem('design_user_info');
  let userInfo = null;
  try {
    userInfo = JSON.parse(localString);
  } catch (e) {
    userInfo = {};
  }
  return userInfo;
}

export function setDesignLocalUserInfo(info = {}) {
  try {
    window.sessionStorage.setItem('design_user_info', JSON.stringify(info));
  } catch (e) {}
}

export function clearDesignLocalUserInfo() {
  try {
    window.sessionStorage.removeItem('design_user_info');
  } catch (e) {}
}

export function getPersonalCenterTabIndex() {
  const localString = window.localStorage.getItem('center_tab_info');
  let centerTabIndex = null;
  try {
    centerTabIndex = JSON.parse(localString);
  } catch (e) {
    centerTabIndex = '1';
  }
  return centerTabIndex;
}

export function setPersonalCenterTabIndexAndFresh(index) {
  window.localStorage.setItem('center_tab_info', JSON.stringify(index));
  window.location.reload();
}

export function cleanTabIndex() {
  window.localStorage.removeItem('center_tab_info');
}

export function getUrlParams(queryName) {
  const query = window.location.search.substring(1);
  // console.log('query = ', query);
  const vars = query.split('&');
  // console.log('vars = ', vars);
  for (let i = 0; i < vars.length; i++) {
    const temp = vars[i].split('=');
    if (temp[0] === queryName) {
      return temp[1];
    }
  }
  return null;
}

export function setCookie(name, value) {
  let Days = 10;
  let exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie = name + '=' + escape(value) + ';expires=' + exp.toGMTString();
}

export function getCookie(name) {
  let arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
}

export function delCookie(name) {
  let exp = new Date();
  exp.setTime(exp.getTime() - 1);
  let c = getCookie(name);
  if (c != null) document.cookie = name + '=' + c + ';expires=' + exp.toGMTString();
}

export function desensitization(mobile) {
  const m = String(mobile);
  const target = m.substring(0, 3) + '*****' + m.substring(8);
  return target;
}

export const UserRole = {
  designer: 1,
  projectManager: 2,
};

export const enterprisesRole = {
  '1': '个人',
  '2': '企业 ',
};

export const projectStatus = {
  '1': '待审核',
  '2': '报名中',
  '3': '筛选中',
  '4': '待签约',
  '5': '设计阶段',
  '6': '交付阶段',
  '7': '已完成',
};

export const ResourceType = {
  RESOURCE: 1,
  PLAN: 2,
  EXAMPLE: 3,
  ARTICLE: 4,
};

export const globalUrlPrefix = () => {
  let url = '';
  if (process.env.NODE_ENV === 'development') {
    /**
     * 侯志京 'http://192.168.80.26:8012/api'
     * 陈体志 'http://192.168.80.19:8025/api'
     */
    url = 'https://xqnnew.xqntech.com/api';
  } else if (process.env.NODE_ENV === 'production') {
    // url = 'https://xqnnew.xqntech.com/api';//测试
	// url = 'https://back.xqntech.com/api';//线上
	url = 'https://devback.xqntech.com/api';//预发布
  }
  return url;
};
